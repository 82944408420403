import React from 'react'
import ServiceSidebare from './ServiceSidebare'
import details1e from '../../assets/images/services/services-details1e.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContente = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1e} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Material Management </span>
                            <h3>About this Service</h3>
                            <p>One of the biggest challenges in any organization is the supply of proper quality of materials manufacturing standard products .that is why avoidance of material wastage helps in controlling the cost of production.</p>
                            <p>Our quality MM service will help you achieve the maximum efficiency of the manufacturing unit. Also management of inventories at pre-determined levels to stabilize investments. Effective material management needs the development of a high-end, integrated, and coordinated system involving budgeting, forecasting, procurement, receiving functions, warehousing, manufacturing, distributing, and actual sales. Material management should consider both the theory of cost management and management of inventories and the practical mechanism of cost calculations and record-keeping</p>
                            <p>Some of the key features of our MM Service is as follows:</p>
                            <li>It deals with Inventory Management and Material Management.</li>
                            <li>It is a process that keeps in check the scarcity or violation in the Supply Chain of a company.</li>
                            <li>It Manages Procurement activities.</li>
                            <li>To accelerate productivity and cut costs, it manages the Material (products/services) and resources of a company.</li>
                            <li>It handles Master Data, Valuation of Material, Material Requirement, Invoice Verification, etc.</li>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>Minimize Cost of Operation</li>
                                            <li>Reduce Inventory Losses</li>
                                            <li>Better Cost Management</li>
                                            <li>Efficient Procurement</li>
                                            <li>Accelerate Productivity</li>
                                            <li>Enhance transparency</li>
                                            <li>Improve end-to-end Visibility</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>We provide the best MM service through which you can deal with both material management and inventory management, which ensures no shortage of material in the supply chain process.
                            Our MM service helps to speed up material management and procurement activities. It helps to accelerate productivity and reduce costs while maintaining accommodations for frequent changes in a business environment. It helps organizations to deal with various business aspects like Procurement, Master data, Material valuation, Inventory management, Invoice verification, Material requirement planning, etc.</p>
                            <p>In this technology-driven world, Material Management remains in significant demand as all businesses require to handle their material reliably. Our MM Service helps organizations stay updated and run efficiently. </p>
                            <h3>Application Areas</h3>
                            <p>Related Industries/Application Domains</p>
                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Banking Sector
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Insurance Companies
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Information and technologies
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Utilities
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Chemical
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Pharmaceuticals
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Retail
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Oil and Energy
                                    </div>
                                </div>

                                <div className="col-lg-5 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Telecommunications
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Procurement and Material Management Application Domain
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>MM</span></li>
                                <li><span>MRP</span></li>
                                <li><span>FI</span></li>
                                
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebare />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContente