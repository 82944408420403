import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContente from '../components/ServiceDetailse/ServiceDetailsContente'
import RelatedServicese from '../components/ServiceDetailse/RelatedServicese'

const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Material Management"
                homePageText="Home"
                homePageUrl="/"
                activePageText="Service Details"
            />
            <ServiceDetailsContente />
            <RelatedServicese />
            <Footer />
        </Layout>
    );
}

export default Details