import React from 'react'
import { Link } from 'gatsby'
import icon1 from '../../assets/images/services/service-icon1.png'
import icon2 from '../../assets/images/services/service-icon2.png'
import icon3 from '../../assets/images/services/service-icon3.png'

const RelatedServicese = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <h2>More Services You Might Like</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={icon1} alt="about" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Order to Cash
                                </Link>
                            </h3>
                            <p>Order to Cash business process with the help of our accelerators & experience gained in more than a decade of helping clients implement their SAP ECC/R3 systems.</p>


                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/service-detailsa">
                                Lead to Cash Order
                                </Link>
                            </h3>
                        <p>Helping you implement Lead to Cash process combining multiple SAP solutions enriched with AI & experience management scenarios.</p>


                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon3} alt="about" />
                            </div>
                            <h3>
                                <Link to="/service-detailsh">
                                BEX & BW
                                </Link>
                            </h3>
                        <p>Helping you implement SAP Business Explorer & Business Warehouse solutions to improve your business decision making processes.</p>


                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RelatedServicese