import React from 'react'
import { Link } from 'gatsby'

const ServiceSidebare = () => {
    return (
        <div className="services-details-info">
            <ul className="services-list">
                <li>
                    <Link to="/service-detailsf" className="active">
                        Plant Maintenance
                    </Link>
                </li>
                <li>
                    <Link to="/service-detailsg">
                         Project Systems
                    </Link>
                </li>
                <li>
                    <Link to="/service-detailsd">
                        Controlling 
                    </Link>
                </li>
                <li>
                    <Link to="/service-detailsc">
                        Financial Accounting
                    </Link>
                </li>

            </ul>

           

            <div className="services-contact-info">
                <h3>Contact Info</h3>

                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Phone:</span>
                        <a href="tel:+15877230825">+1 (780)607 0543</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Location:</span>
Fort McMurray, Alberta <br /> Canada
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-envelope'></i>
                        </div>
                        <span>Email:</span>
                        <a href="mailto:">Info@diamondsconsultinginc.com</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ServiceSidebare